function filterData() {
    $.ajax({
        type: "POST",
        url: $('.netMerchants form').attr('action'),
        data: $('.netMerchants form').serialize(),
        dataType: 'json',
        success: function (result) {
            if ( result.showCompetenceStates == 1 )
            {
                $('.netMerchants .states').removeClass('hidden');
                $('.netMerchants .filter select.state').html(result.states);

                if ( !$('.netMerchants .zip').hasClass('hidden'))
                {
                    $('.netMerchants .zip').addClass('hidden');
                }

                $('.netMerchants .zip input').val('');
            }
            else if (result.showZipCodes == 1) {
                $('.netMerchants .zip').removeClass('hidden');


                if ( !$('.netMerchants .states').hasClass('hidden'))
                {
                    $('.netMerchants .states').addClass('hidden');
                }
                $('.netMerchants .filter select.state').val(null).trigger('change');
            } else {
                if ( !$('.netMerchants .states').hasClass('hidden'))
                {
                    $('.netMerchants .states').addClass('hidden');
                }
                if ( !$('.netMerchants .zip').hasClass('hidden'))
                {
                    $('.netMerchants .zip').addClass('hidden');
                }

                $('.netMerchants .zip input').val('');
                $('.netMerchants .filter select.state').val(null).trigger('change');
            }


            if (result.showCountries == 1) {
                $('.netMerchants .countries').removeClass('hidden');
            } else {
                if ( !$('.netMerchants .countries').hasClass('hidden'))
                {
                    $('.netMerchants .countries').addClass('hidden');
                }
            }

            $('.netMerchants .filter select.country').html(result.countries);
            $('.netMerchants .results .wrap').html(result.merchants);
        },
        error: function (result) {
            if (undefined !== result.message) {
                console.log(result.message);
            } else {
                console.log(result);
            }
        }
    });
}

$(function () {

    $('.netMerchants .filter select').select2({
        minimumResultsForSearch: Infinity,
        width: 'style'
    });

    $('.netMerchants form').on('submit', function (e) {
        e.preventDefault();
        return false;
    });

    $('.netMerchants .filter select.country, .netMerchants .filter select.state').on('select2:select', function (e) {
        var selected = $('.netMerchants .filter select.country').val();
        if (parseInt(selected) > -1) {
            filterData();
        } else {
            $('.netMerchants .results .wrap').html('');
        }
    });

    $('.netMerchants .filter select.continent').on('select2:select', function (e) {
        if ( !$('.netMerchants .states').hasClass('hidden'))
        {
            $('.netMerchants .states').addClass('hidden');
        }
        $('.netMerchants .filter select.state').val(null).trigger('change');

        if ( !$('.netMerchants .zip').hasClass('hidden'))
        {
            $('.netMerchants .zip').addClass('hidden');
        }
        $('.netMerchants .zip input').val('');

        var selected = $('.netMerchants .filter select.continent').val();
        if (parseInt(selected) > -1) {
            $('.netMerchants .filter select.country').val(null).trigger('change');
            filterData();
        } else {
            if ( !$('.netMerchants .countries').hasClass('hidden'))
            {
                $('.netMerchants .countries').addClass('hidden');
            }

            $('.netMerchants .results .wrap').html('');
        }
    });

    var zipTimeoutId = null;
    $('.netMerchants .filter .zip input').keyup(function () {
        clearTimeout(zipTimeoutId);
        zipTimeoutId = setTimeout(function () {
            var zipcode = $('.netMerchants .filter .zip input').val();
            if (zipcode.length > 1) {
                filterData();
            }
        }, 1000);
    });
});
