// mobile trigger menu helper
const mobileTrigger = document.getElementById("mobileTrigger");
if (mobileTrigger) {
    mobileTrigger.parentNode.insertBefore(document.createElement('span'), mobileTrigger.nextSibling);
    mobileTrigger.addEventListener("click", function () {
        mobileTrigger.classList.toggle('active');
        if (document.getElementById('navMain')) {
            document.getElementById('navMain').classList.toggle('active');
        }
    }, false);

    // menu trigger helper
    // document.querySelector("#mobileTrigger ~ span").addEventListener("click", function () {
    //     mobileTrigger.classList.remove('active');
    //     location.hash = '#';
    // }, false);
}

var closeMenu = (e) => {
    removeMenuImg();
    document.querySelectorAll('nav .active').forEach(function (item) {
        if (item.classList) {
            item.classList.remove('active');
        }
    });
    document.querySelector('nav a.close').parentNode.removeChild(document.querySelector('nav a.close'));
    // document.querySelector('#canvas  > header').classList.remove('active');
}

// close menu at header click event
// document.querySelector('#canvas > header').addEventListener('click', function (e) {
//     var el = ['UL', 'LI'];
//     if (e.target.classList.contains('navigation') || el.includes(e.target.tagName)) {
//         if (document.querySelectorAll('nav .active').length) {
//             if (window.matchMedia('(min-width: 769px)').matches) {
//                 closeMenu(e);
//             }
//         }
//     }
// });
// document.querySelector('#canvas > header').classList.add('js');

// JS fallback for older browsers with no :focus-within support
document.querySelectorAll('nav#navMain a').forEach(function (item) {
    item.addEventListener("click", function (e) {
        var currentItem = this;
        removeMenuImg();

        this.parentNode.parentNode.querySelectorAll('.active').forEach(function (sibling) {
            if (currentItem != sibling) {
                sibling.classList.remove('active');
            }
        });
        if (this.classList.contains('has-children')) {
            e.preventDefault();
        }
        if (this.classList.contains('active')) {
            this.classList.remove('active');
            // remove close button
            var closeButton = this.parentNode.querySelector('a.close');
            if (closeButton) {
                closeButton.parentNode.removeChild(closeButton);
            }

            this.blur();
            document.querySelector('main').removeEventListener('click', closeMenu);
        } else {
            this.classList.add('active');
            document.querySelector('main').addEventListener('click', closeMenu, false);

            if (this.parentNode.querySelector('ul').classList.contains('level2')) {
                var close = document.createElement('a');
                close.classList.add('close');
                close.setAttribute('href', '#');
                close.addEventListener('click', closeMenu, false);
                // var span = document.createElement('span');
                // close.appendChild(span);
                // close.appendChild(span);
                this.parentNode.appendChild(close);
            }
        }

        if (document.querySelectorAll('a.active').length === 0) {
            document.querySelector('body > header').classList.remove('active');
        }

    }, false);
});

var removeMenuImg = (href = false) => {
    if (href) {
        var exists = document.querySelector('#navMain > a.img-wrapper[href="' + href + '"]');
    }
    if (!exists) {
        var exists = document.querySelector('#navMain > a.img-wrapper');
    }
    if (exists) {
        exists.parentNode.removeChild(exists);
    }
}

// add event listener for hover for .level2 > li > a and read out a data attribute img
document.querySelectorAll('.level3 > li > a > span').forEach(function (item) {
    item.addEventListener('mouseover', function (e) {
        // get href attribute
        var href = this.parentNode.getAttribute('href');
        var img = this.dataset.img;
        removeMenuImg(href);
        if (img && window.matchMedia('(min-width: 769px)').matches) {
            // add spans for arrow inside image
            //arrow 1
            var imageArrow1 = document.createElement('span');
            imageArrow1.classList.add('imageArrow1');
            //arrow 2
            var imageArrow2 = document.createElement('span');
            imageArrow2.classList.add('imageArrow2');
            //arrow 3
            var imageArrow3 = document.createElement('span');
            imageArrow3.classList.add('imageArrow3');
            //arrow 4
            var imageArrow4 = document.createElement('span');
            imageArrow4.classList.add('imageArrow4');

            // create image element
            var imgEl = document.createElement('img');
            imgEl.setAttribute('src', img);
            imgEl.classList.add('level3-img');

            // create container for arrow and add span elements
            var arrowContainer = document.createElement('div');
            arrowContainer.classList.add('arrowContainer');
            arrowContainer.appendChild(imageArrow1);
            arrowContainer.appendChild(imageArrow2);
            arrowContainer.appendChild(imageArrow3);
            arrowContainer.appendChild(imageArrow4);

            // create a link element
            var aEl = document.createElement('a');
            aEl.setAttribute('href', href);
            aEl.classList.add('img-wrapper');
            aEl.appendChild(imgEl);
            aEl.appendChild(arrowContainer);

            // add li element to parent
            document.querySelector('#navMain').appendChild(aEl);         
        }

    });
});

var currentLanguageTarget = document.querySelector('#language > div > a');
var languages = document.querySelector('div.more');
currentLanguageTarget.addEventListener('click', (e) => {
    e.preventDefault();
    // select e.target.parentNode sibling .more
    if (languages.classList.contains('active')) {
        languages.classList.remove('active');
    } else {
        languages.classList.add('active');
    }
});





// document.querySelectorAll('#main > ul > li > a').forEach(function (item) {
//     item.addEventListener('click', function (e) {
//         console.log(e.target.parentNode.classList.contains('has-children'));
//         if (e.target.parentNode.classList.contains('active') && e.target.parentNode.classList.contains('has-children')) {
//             document.querySelector('body > header').classList.add('active');
//         }
//     });
// })